@charset "utf-8";

//@tailwind base;
@tailwind components;


/*!
  purgecss start ignore
*/

div, a, button, span {
  outline: none;
}

.pd-body {
  position: relative;
  -webkit-font-smoothing: antialiased;
  font-family: "helvetica-neue-lt",Helvetica,Arial;
  font-size: 15px;
  line-height: 1.3em;
  position: relative;
  z-index: 0;
  color: #4a4a4a;
  background-color: white;
  background-image: none;
  display: block;
  /* align-items: unset; */
  min-height: 100vh;
}

.nav__bg {
  background-image: url('/assets/img/nomura-header.svg');
  background-repeat: no-repeat;
  background-size: cover;
}
.mobile__dropdown {
  height: calc(100vh - 60px);
}
.plus__icon {
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.pd-header__container {
  height: auto;
  @media screen and ( min-height: 539px ){
    height: 80vh;
  }
  @media screen and ( min-height: 700px ){
    height: 500px;
  }

  //@media screen and ( min-height: 860px ){
  //  height: auto;
  //}


  &.height__auto {
    height: auto;
    @media screen and ( min-height: 539px ){
      height: auto;
    }
    @media screen and ( min-height: 860px ){
      height: auto;
    }
  }
}

.min__h__header {
  height: 100%;
  min-height: 456px;
  @media screen and ( min-height: 539px ){
    min-height: auto;
  }

  @media screen and ( min-height: 700px ){
    height: 100%;
    min-height: 410px;
  }

  //@media screen and ( min-height: 860px ){
  //  height: 100%;
  //  min-height: 456px;
  //}
}

.min__h__podcast__header {
  height: 100%;
  min-height: 395px;
  @media screen and ( min-height: 539px ){
    min-height: auto;
  }

  @media screen and ( min-height: 700px ){
    height: 100%;
    min-height: 395px;
  }

  //@media screen and ( min-height: 860px ){
  //  height: 100%;
  //  min-height: 395px;
  //}
}

.min__h__category__header {
  height: 100%;
  min-height: 160px;
  padding-top: 30px;
}

.pd-hidden {
  height: 0;
  opacity: 0;
  visibility: hidden;
  transition: all ease-in-out .3s;
}

.header-v2 {
  .is-not-top & {
    background-color: white;
    transition: all ease-in-out 0.75s;

  }
  &__logo {
    &__icon {
      width: 150px;
      height: 25px;
      path {
        fill: #fff;
      }
      .is-not-top & {
        path {
          fill: #CA2420;
        }
      }

      &.red {
        path {
          fill: #CA2420;
        }
      }

      transition: all ease-in-out .3s;

      @screen md {
        width: 195px;
        height: 35px;
        path {
          fill: white;
        }

        .is-not-top & {

          path {
            fill: #CA2420;
          }
        }
      }
    }
  }
}
a {
  text-decoration: none;
}
ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.disclaimer__text {


}

.story__card {
  transition: transform 0.5s;
  transform: scale3d(1.0, 1.0, 1.0);
  &:hover {
    transition: transform 0.5s;
    transform: scale3d(1.01, 1.01, 1.01);
  }
}

.podcast__listing {

  .triangle {
    mix-blend-mode: multiply;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      opacity: 0.8;
    }
  }

  &:hover {
    .triangle {
      transition: transform 1.5s;
      mix-blend-mode: multiply;
      transform: translateX(20%);
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        opacity: 0.8;
      }

    }

  }
}

.pagination__button {
  &:hover {
    svg {
      path {
        stroke: white;
      }
    }
  }

  &.pag__disabled {
    cursor: auto;
    pointer-events: none;
    opacity: 0.2;
  }

}

.has-sub-menu {

  &:hover {
    .sub__nav__menu {
      display: block;
      opacity: 1.0;
    }
  }
}

.series__item {
  margin-bottom: 0!important;
}


a {
  transition: all 0.5s ease;
}





/*!
  purgecss end ignore
*/

@tailwind utilities;

@layer utilities {

  .triangle__down {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 14px 13.5px 0 13.5px;
    border-color: #ca2320 transparent transparent transparent;
  }

  //.text-white {
  //  color: white !important;
  //}
  //.text-red {
  //  color: #CA2420 !important;
  //}
  .text-light-gray {
    color: #9F9F9F !important;
  }
  .translate-50 {
    transform: translate(-50%,-50%);
  }
  .translate-arrow {
    transform: translateX(-100%);
  }
  .translate-subnav {
    transform: translateX(-50%);
  }
  .scale-hover {
    transform: scale3d(1.01, 1.01, 1.01);
  }

  .nav-triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #ffffff transparent;
  }

  .rotate-close-menu {
    transform: rotate(45deg);
    top: 10%;
  }

  .rotate-0 {
    transform: rotate(0deg);
  }
  .rotate-45 {
    transform: rotate(45deg);
  }
  .rotate-90 {
    transform: rotate(90deg);
  }
  .rotate-180 {
    transform: rotate(180deg);
  }
  .rotate-270 {
    transform: rotate(270deg);
  }
  .group:hover .rotate-45 {
    transform: rotate(45deg);
  }

}
